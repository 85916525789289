import { AnalyticsManager } from 'utilities'

/*
 ** Analytics class
 */
export class ClientSignUpAnalytics {
  static onNextQuestion({
    clientId,
    surveySectionName,
    surveyQuestionIds,
    surveyQuestionDescriptions,
    surveyQuestionResponseIds,
    surveyQuestionResponseValue,
    surveyQuestionResponseDescription,
    signUpVariant,
    location,
    ...rest
  }) {
    AnalyticsManager.logEvent(
      `Client Sign Up - ${location ? location : 'Press Next'}`,
      {
        'Client ID': clientId,
        'Survey Section Name': surveySectionName,
        'Survey Question IDs': surveyQuestionIds,
        'Survey Question Descriptions': surveyQuestionDescriptions,
        'Sign Up Variant': signUpVariant,
        'Survey Question Response IDs': surveyQuestionResponseIds,
        'Survey Question Response Value': surveyQuestionResponseValue,
        'Survey Question Response Description':
          surveyQuestionResponseDescription,
        ...rest,
      }
    )
  }

  static onPreviousQuestion({
    clientId,
    surveySectionName,
    surveyQuestionIds,
    surveyQuestionDescriptions,
    signUpVariant,
    ...rest
  }) {
    AnalyticsManager.logEvent('Client Sign Up - Press Back', {
      'Client ID': clientId,
      'Survey Section Name': surveySectionName,
      'Survey Question IDs': surveyQuestionIds,
      'Survey Question Descriptions': surveyQuestionDescriptions,
      'Sign Up Variant': signUpVariant,
      ...rest,
    })
  }

  static onViewQuestion({
    clientId,
    surveySectionName,
    surveyQuestionIds,
    surveyQuestionDescriptions,
    signUpVariant,
    location,
    ...rest
  }) {
    AnalyticsManager.logEvent(
      `Client Sign Up - ${location ? location : 'View Survey Screen'}`,
      {
        'Client ID': clientId,
        'Survey Section Name': surveySectionName,
        'Survey Question IDs': surveyQuestionIds,
        'Survey Question Descriptions': surveyQuestionDescriptions,
        'Sign Up Variant': signUpVariant,
        ...rest,
      }
    )
  }

  static onPhoneNumberAcquired({ clientId }) {
    // Amplitude
    AnalyticsManager.logEvent('Client Sign Up - Phone Number Acquired', {
      'Client ID': clientId,
    })

    // Google
    window.dataLayer.push({
      event: 'google_event',
      event_action: 'Acquired',
      event_category: 'Phone Number',
      event_label: clientId,
    })
  }

  static thriveGtmFbTracking({ email, firstName, lastName, phoneNumber }) {
    window.dataLayer.push({
      event: 'user_info_captured',
      email,
      firstName,
      lastName,
      phoneNumber,
    })
  }

  static onReengagement({ coachId } = {}) {
    AnalyticsManager.logEvent('Client Sign Up - Start Reengagement Flow', {
      'Referred By Coach ID': coachId,
    })
  }

  static onCancelReengagement() {
    AnalyticsManager.logEvent('Client Sign Up - Cancel Reengagement Flow')
  }

  static onResendReengagementText() {
    AnalyticsManager.logEvent('Client Sign Up - Resend Reengagement Text')
  }

  static onFollowReengagementSubscribeLink() {
    AnalyticsManager.logEvent(
      'Client Sign Up - Follow Reengagement Subscribe Link'
    )
  }

  static onViewAnotherCoach({ clientId, coachId }) {
    AnalyticsManager.logEvent(
      'Client Sign Up - Match Screen - View Another Coach Click',
      {
        'Client ID': clientId,
        'Coach ID': coachId,
      }
    )
  }

  static onViewCoachProfile({ clientId, coachId }) {
    AnalyticsManager.logEvent(
      'Client Sign Up - Match Screen - View Coach Profile Click',
      {
        'Client ID': clientId,
        'Coach ID': coachId,
      }
    )
  }

  static onTrainWithButtonClick({ clientId, coachId }) {
    AnalyticsManager.logEvent(
      'Client Sign Up - Match Screen - Train With Button Click',
      {
        'Client ID': clientId,
        'Coach ID': coachId,
      }
    )
  }
  static onCardSelection({ clientId, coachId }) {
    AnalyticsManager.logEvent(
      'Client Sign Up - Match Screen - Coach Card Click',
      {
        'Client ID': clientId,
        'Coach ID': coachId,
      }
    )
  }

  static onViewContactInfoModal({ clientId }) {
    AnalyticsManager.logEvent('Client Sign up - View Contact Info Modal', {
      'Client ID': clientId,
    })
  }

  static onShowMore({ clientId }) {
    AnalyticsManager.logEvent(
      'Client Sign up - Match Screen - View More Click',
      {
        'Client ID': clientId,
      }
    )
  }

  static onPageView({ clientId }) {
    AnalyticsManager.logEvent(
      'Client Sign up - Match Screen - Experiment View',
      {
        'Client ID': clientId,
      }
    )
  }
}
