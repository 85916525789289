import { useMemo } from 'react'
import { NetworkStatus, useQuery as useApolloQuery } from '@apollo/client'

import useApolloCacheUpdate from 'hooks/use-apollo-cache-update'

const useQuery = (...args) => {
  const apolloPayload = useApolloQuery(...args)
  const isInitialLoad = apolloPayload.networkStatus === NetworkStatus.loading
  const updateCache = useApolloCacheUpdate(args[0], args[1], isInitialLoad)

  const value = useMemo(() => {
    return {
      ...apolloPayload,
      updateCache,
      isInitialLoad,
    }
  }, [apolloPayload, updateCache, isInitialLoad])

  return value
}

export default useQuery
