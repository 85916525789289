import { formatCurrency } from 'utilities/numbers'
import { BILLING_TERM_TEXT_BY_BILLING_PERIOD_IN_MONTHS } from 'constants/products'
import { getPriceInDollars } from 'utilities/stripe'
import s from './styles.module.css'
import addDays from 'date-fns/add_days'

export const pricingTextItems = ({ price, coupon }) => {
  const { billingPeriodInMonths } = price
  const billingTermText =
    BILLING_TERM_TEXT_BY_BILLING_PERIOD_IN_MONTHS[billingPeriodInMonths]
  const [priceInDollars, formattedPriceInDollars] = getPriceInDollars({
    price,
    coupon,
  })
  const dailyPriceInDollars =
    Math.round((priceInDollars / billingPeriodInMonths / 30.42) * 100) / 100

  const formattedDailyPriceInDollars = formatCurrency(dailyPriceInDollars)
  const basePriceInDollars = price.unitAmount / 100

  if (coupon && billingPeriodInMonths === 1) {
    const { amountOff, percentOff, duration } = coupon
    let items = [
      <>
        <span className={s.boldPrice}>{formattedPriceInDollars} due today</span>
      </>,
    ]

    if (duration === 'once') {
      if (amountOff) {
        items.push(
          <>
            (${amountOff / 100} discount applied for first month, $
            {basePriceInDollars}/month thereafter)
          </>
        )
      } else {
        items.push(
          <>
            ({percentOff}% discount applied for first month, $
            {basePriceInDollars}/month thereafter)
          </>
        )
      }
    } else {
      if (amountOff) {
        items.push(<>(${amountOff / 100} discount applied permanently)</>)
      } else {
        items.push(<>({percentOff}% discount applied permanently)</>)
      }
    }

    return [...items, <>{formattedDailyPriceInDollars}/day</>]
  }

  let items = [
    <>
      <span className={s.boldPrice}>{formattedPriceInDollars} due today</span> -
      covers {billingTermText} of daily training
    </>,
  ]

  if (billingPeriodInMonths === 1) {
    items.push(<>{formattedDailyPriceInDollars}/day</>)
  } else {
    const getDiscountText = () => {
      if (billingPeriodInMonths === 6) return '10% off'

      return '20% off'
    }

    const getCouponText = () => {
      if (!coupon || !coupon.amountOff) return ''

      return ` and $${coupon.amountOff / 100} discount applied`
    }

    items.push(
      <>
        {formattedDailyPriceInDollars}/day ({getDiscountText()}
        {getCouponText()})
      </>
    )
  }

  return items
}

export const unixThirtyDaysFromNow = () => addDays(new Date(), 30).getTime()
export const unixSevenDaysFromNow = () => addDays(new Date(), 7).getTime()

export const billingPeriodInWords = (billingPeriod, capitalize = false) => {
  const words = {
    1: 'monthly',
    6: `${billingPeriod} months`,
    12: 'yearly',
  }

  return capitalize
    ? words[billingPeriod].replace(/\b([a-zA-Z])/g, c => c.toUpperCase())
    : words[billingPeriod]
}
