import { formatCurrency } from 'utilities/numbers'
import * as R from 'ramda'

export const getPriceInDollars = ({
  basePriceInCents = 9500,
  price,
  coupon,
  isFree = false,
}) => {
  let { unitAmount } = price

  if (coupon) {
    const { amountOff, percentOff } = coupon

    if (amountOff) {
      unitAmount -= amountOff
    }

    // no % discount for multi-month subscriptions
    if (percentOff && price.billingPeriodInMonths === 1) {
      unitAmount *= 1 - percentOff / 100
    }

    // don't allow coupon to bring price below 0
    unitAmount = Math.max(0, unitAmount)
  }

  const priceInDollars = isFree ? 0 : unitAmount / 100

  return [
    priceInDollars,
    priceInDollars % 1 === 0
      ? formatCurrency(priceInDollars, {
          minimumFractionDigits: 2,
        })
      : formatCurrency(priceInDollars),
    unitAmount,
  ]
}

const roundToTheNearest = (n, multiple) => Math.round(n / multiple) * multiple

export const getDiscountedPrice = ({ price, coupon, isFirstTerm = false }) => {
  const { unitAmount } = price

  if (!coupon) return unitAmount

  const { duration, percentOff, amountOff } = coupon

  if (!isFirstTerm && duration !== 'forever') return unitAmount

  if (percentOff) {
    return unitAmount * (1 - percentOff / 100)
  } else if (amountOff) {
    return Math.max(0, unitAmount - amountOff)
  }
}

export const getDailyPrice = ({
  price,
  coupon = undefined,
  isFree = false,
  shouldRound = true,
  roundTo = 100,
}) => {
  if (isFree) return [0, formatCurrency(0)]

  const discountedPrice = getDiscountedPrice({
    price,
    coupon,
    isFirstTerm: true,
  })
  const { billingPeriodInMonths } = price
  // const dailyPrice = discountedPrice / billingPeriodInMonths / 30.42
  // Note: Changing to 31 to make the numbers look smaller
  const dailyPrice = discountedPrice / billingPeriodInMonths / 31

  return [
    shouldRound ? Math.round(dailyPrice / 100) : Math.round(dailyPrice) / 100,
    formatCurrency(roundToTheNearest(dailyPrice, roundTo) / 100),
  ]
}

export const getMonthlyPrice = ({
  price,
  coupon = undefined,
  isFree = false,
  shouldRound = true,
}) => {
  if (isFree) return [0, formatCurrency(0)]

  const discountedPrice = getDiscountedPrice({
    price,
    coupon,
    isFirstTerm: true,
  })
  const { billingPeriodInMonths } = price
  const monthlyPrice = discountedPrice / billingPeriodInMonths

  return [
    shouldRound
      ? Math.round(monthlyPrice / 100)
      : Math.round(monthlyPrice) / 100,
    formatCurrency(Math.round(monthlyPrice) / 100),
  ]
}

export const getLiveUpgradePrice = ({
  coupon,
  products,
  billingPeriodInMonths = 1,
  sessions = 1,
}) => {
  const price = R.find(
    R.propEq('billingPeriodInMonths', billingPeriodInMonths),
    products[0].prices
  )

  const secondTermPriceInDollars = Math.round(
    getDiscountedPrice({
      price,
      coupon,
      isFirstTerm: false,
    }) / 100
  )

  // const dailyPrice = getDailyPrice({ price, coupon })
  const live = R.find(R.propEq('callsPerWeek', sessions))(products)

  const livePrice = R.find(
    R.propEq('billingPeriodInMonths', billingPeriodInMonths),
    live.prices
  )

  const secondTermLivePriceInDollars = Math.round(
    getDiscountedPrice({
      price: livePrice,
      coupon,
      isFirstTerm: false,
    }) / 100
  )

  const difference = secondTermLivePriceInDollars - secondTermPriceInDollars

  return difference
}

export const getProductTitle = product => {
  return product.descriptiveName || product.name
}

export const getCouponNote = ({ coupon }) => {
  if (coupon?.duration !== 'forever') return null

  return coupon.percentOff
    ? `, after the permanent ${coupon.percentOff}% employee discount`
    : `, after the permanent $${coupon.amountOff / 100}% employee discount`
}

export const getTrialDaysDuration = (trialDays, forceSingularDay = false) => {
  if (!trialDays) return null
  if (trialDays === 30) return 'month'
  if (trialDays === 7) return 'week'

  return `${trialDays} ${forceSingularDay || trialDays === 1 ? 'day' : 'days'}`
}
