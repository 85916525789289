import { createContext } from 'react'

export default createContext({
  signUpSurveyQuestions: null,
  surveyId: null,
  query: {},
  onAnchorLinkClick: () => {},
  coach: null,
  isCoachPage: null,
  locationDetails: null,
  localPageType: null,
  isBlog: null,
  signUp: () => {},
  coupon: null,
  isSafari: false,
  selectedPriceId: null,
  selectPriceId: () => {},
  hasBanner: null,
})
