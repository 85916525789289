import { AnalyticsManager } from 'utilities'

/*
 ** Analytics class
 */
export class ChateButtonAnalytics {
  static onChatButtonClick({ location }) {
    AnalyticsManager.logEvent(
      `${location ? location + ' -' : ''} Live Chat Click`
    )
  }
}
