/* global $crisp */
import cx from 'classnames'

import { HIDE_CRISP_WIDGET } from 'constants/styles'
import * as R from 'ramda'
import { useEffect, useState } from 'react'
import { ChateButtonAnalytics } from './analytics'
import s from './styles.module.css'
import { ChatIcon } from 'components/design-system/kickoff-icons'

const ChatButton = ({ buttonClassName, analyticProps = { location: '' } }) => {
  const [show, setShow] = useState(false)

  const onClick = () => {
    ChateButtonAnalytics.onChatButtonClick({ location: analyticProps.location })
    $crisp.push(['do', 'chat:toggle'])
  }

  useEffect(() => {
    let checkInterval
    let checks = 0

    const checkForCrisp = () => {
      checks += 1

      if (checks > 20) return clearInterval(checkInterval)

      if (typeof $crisp === 'undefined' || !$crisp || R.isEmpty($crisp)) {
        return
      } else {
        setShow(true)
        $crisp.push(['config', 'sound:mute', [true]])
        clearInterval(checkInterval)
      }
    }

    // check for $crisp instance every 200ms
    // give up after 4sec
    checkForCrisp()
    checkInterval = setInterval(checkForCrisp, 200)

    return () => {
      clearInterval(checkInterval)
    }
  }, [])

  if (!show) return HIDE_CRISP_WIDGET

  return (
    <>
      <button className={cx(s.button, buttonClassName)} onClick={onClick}>
        <ChatIcon className={s.icon} />
        <div className={s.buttonText}>Live Chat</div>
      </button>
      {HIDE_CRISP_WIDGET}
    </>
  )
}

export default ChatButton
