import * as R from 'ramda'

// getRandomInt(3) => expected output: 0, 1 or 2
export const getRandomInt = ceil => {
  return Math.floor(Math.random() * Math.floor(ceil))
}

export const formatCurrency = (n, options = {}) => {
  return n.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options,
  })
}
export const formatCents = cents => {
  if (R.isNil(cents)) return null

  return `$${(cents / 100.0).toLocaleString()}`
}
export const formatCentsPretty = cents => {
  if (R.isNil(cents)) return null
  return formatCurrency(cents / 100.0, {
    minimumFractionDigits: cents % 100 == 0 ? 0 : 2,
  })
}
