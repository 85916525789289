import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from '../kickoff-icons/base-svg-icon'

export const CheckIcon = ({
  className,
  color = '#62697D',
  size,
  title,
  width = 6,
  height = 6,
  strokeWidth = 1.8,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 6 6"
      width={width}
      height={height}
      {...rest}>
      <path
        id="Check Icon"
        d="M0.899902 3.3L2.0999 4.5L5.0999 1.5"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSvgIcon>
  )
}
