import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useRef } from 'react'

const useApolloCacheUpdate = (query, { variables } = {}, isInitialLoad) => {
  const apolloClient = useApolloClient()

  const updateCache = useCallback(
    transformData => {
      apolloClient.writeQuery({
        query,
        variables,
        data: transformData(
          apolloClient.readQuery({
            query,
            variables,
          })
        ),
      })
    },
    [apolloClient, query, variables]
  )

  // supports updateCache being called before initial load is complete.
  // we wait for the initial response to end up in the cache, then we apply the queued transformations
  const initialCacheUpdates = useRef([])

  useEffect(() => {
    if (!isInitialLoad && initialCacheUpdates.current.length) {
      for (const transformFn of initialCacheUpdates.current) {
        updateCache(transformFn)
      }

      initialCacheUpdates.current = []
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoad])

  const updateCacheSafe = useCallback(
    transformFn => {
      if (isInitialLoad) {
        initialCacheUpdates.current.push(transformFn)
      } else {
        updateCache(transformFn)
      }
    },
    [updateCache, isInitialLoad]
  )

  return updateCacheSafe
}

export default useApolloCacheUpdate
