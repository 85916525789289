import cx from 'classnames'
import { CheckIcon } from 'components/design-system/kickoff-icons-v2/check-icon'
import s from './styles.module.css'

interface IDetailItemProps {
  text: string
  className?: string
  checkStyle?: string
  checkHeight?: number
  checkWidth?: number
  textStyle?: string
}

export default function DetailItem({
  text,
  className,
  checkStyle,
  checkHeight,
  checkWidth,
  textStyle,
}: IDetailItemProps) {
  return (
    <div className={cx(s.detailItem, className)}>
      <div className={cx(s.checkCircle, checkStyle)}>
        <CheckIcon height={checkHeight} width={checkWidth} />
      </div>
      <span className={cx(s.detailText, textStyle)}>{text}</span>
    </div>
  )
}
