import { IS_PROD } from 'utilities/environment'

export const WORKOUT_AND_NUTRITION_PRODUCT_ID = IS_PROD
  ? 'prod_QfQl6NOokpedaE'
  : 'prod_Qc3Ep6yWYPgee1'
export const WORKOUT_ONLY_PRODUCT_ID = IS_PROD
  ? 'prod_QfQmfEIdyxbbXg'
  : 'prod_Qc2dpWD4DAR4Lw'
export const NUTRITION_ONLY_PRODUCT_ID = IS_PROD
  ? 'prod_QfQmeYhBfGzh9O'
  : 'prod_Qc3CtN4vsoH8aE'
export const KICKOFF_STANDARD_PRODUCT_ID = IS_PROD
  ? 'prod_D4GdjxjVsZbDU2'
  : 'prod_D50LqUJrDPp9am'
