import { useRef, useEffect } from 'react'

import { useAnalytics } from 'hooks/use-analytics'

export const TrackedSectionView = ({ scrollRef }: { scrollRef?: any }) => {
  const logEvent = useAnalytics()

  const el = useRef<HTMLDivElement | null>(null)
  const hasViewed = useRef<boolean>(false)

  useEffect(() => {
    const scrollDiv =
      scrollRef && scrollRef.current ? scrollRef.current : window

    const handleScroll = () => {
      if (hasViewed.current) return
      if (!el.current) return

      if (
        (scrollDiv.scrollY || scrollDiv.scrollTop) >
        el.current.offsetTop - window.innerHeight
      ) {
        hasViewed.current = true
        logEvent('View')
      }
    }

    scrollDiv.addEventListener('scroll', handleScroll)

    return () => {
      scrollDiv.removeEventListener('scroll', handleScroll)
    }
  }, [logEvent, scrollRef])

  return <div ref={el} />
}
