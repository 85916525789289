const STANDARD_DETAILS = [
  {
    text: 'Daily guidance & unlimited text with your dedicated trainer',
  },
  { text: 'Personalized nutrition coaching' },
  { text: 'Daily custom workouts' },
]

export const STANDARD_DETAILS_B = [
  {
    text: 'Personalized workouts',
  },
  { text: 'Personalized nutrition coaching' },
  { text: 'Daily feedback & guidance from your certified coach' },
  {
    text: '',
    empty: true,
  },
]

const LIVE_DETAILS = [
  { text: 'All of Kickoff standard, plus:', verbose: STANDARD_DETAILS },
  {
    text: 'Weekly 1-on-1 live video workout with your coach',
    bold: true,
  },
]

export const LIVE_DETAILS_B = [
  ...STANDARD_DETAILS_B.filter(x => !x.empty),
  {
    text: 'Weekly 1-on-1 live video workout with your coach',
    bold: true,
  },
]

const LIVE_PLUS_DETAILS = [
  { text: 'All of Kickoff standard, plus:', verbose: STANDARD_DETAILS },
  {
    text: '2 Weekly 1-on-1 live video workouts with your coach',
    bold: true,
  },
]

export const LIVE_PLUS_DETAILS_B = [
  ...STANDARD_DETAILS_B.filter(x => !x.empty),
  {
    text: 'Twice Weekly 1-on-1 live video workouts with your coach',
    bold: true,
  },
]

const LIVE_3X_DETAILS = [
  { text: 'All of Kickoff standard, plus:', verbose: STANDARD_DETAILS },
  {
    text: '3 Weekly 1-on-1 live video workouts with your coach',
    bold: true,
  },
]

const LIVE_4X_DETAILS = [
  { text: 'All of Kickoff standard, plus:', verbose: STANDARD_DETAILS },
  {
    text: '4 Weekly 1-on-1 live video workouts with your coach',
    bold: true,
  },
]

const LIVE_5X_DETAILS = [
  { text: 'All of Kickoff standard, plus:', verbose: STANDARD_DETAILS },
  {
    text: '5 Weekly 1-on-1 live video workouts with your coach',
    bold: true,
  },
]

export const DETAILS_BY_CALLS_PER_WEEK = {
  0: STANDARD_DETAILS,
  1: LIVE_DETAILS,
  2: LIVE_PLUS_DETAILS,
  3: LIVE_3X_DETAILS,
  4: LIVE_4X_DETAILS,
  5: LIVE_5X_DETAILS,
}

export const DETAILS_BY_CALLS_PER_WEEK_B = {
  0: STANDARD_DETAILS_B,
  1: LIVE_DETAILS_B,
  2: LIVE_PLUS_DETAILS_B,
  3: LIVE_3X_DETAILS,
  4: LIVE_4X_DETAILS,
  5: LIVE_5X_DETAILS,
}

export const BILLING_FREQUENCY_TEXT_BY_BILLING_PERIOD_IN_MONTHS = {
  1: 'monthly',
  6: 'every 6 months',
  12: 'yearly',
}

export const BILLING_TERM_TEXT_BY_BILLING_PERIOD_IN_MONTHS = {
  1: '1 month',
  6: '6 months',
  12: '1 year',
}

export const OLD_PRICING_DESCRIPTION = {
  0: '',
  1: '$245 billed monthly',
  2: '$365 billed monthly',
}

export const LEGACY_PRODUCT_KEYS = [
  'standard',
  'live',
  'live_plus',
  'live_3x',
  'live_4x',
  'live_5x',
]
